.card-max-width {
    max-width: 95vw;
  }
  
  .fare-table {
    font-size:small;
    background-color: white;
    /* width: 500px; */
    /* table-layout: fixed; */
  }
  
  .fare-table-header {
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    writing-mode: vertical-rl;
    text-orientation: mixed;
    /* max-height: 4rem; */
  }
  
  .fare-table-header-horizontal {
    white-space: nowrap;
  }
  
  .fare-table-td{
    min-width: 3rem;
    min-height: 3rem;
    max-width: 5rem;
    max-height: 5rem;
    text-align: center;
    vertical-align: middle;
    /* margin: 0; */
    /* padding: 0; */
    position: relative;
  }
  
  .fare-table-td-input{
    border: none;
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
    text-align: center;
  }
  
  .fare-table-td-input:hover{
    cursor: pointer;
    background-color: #362fa9;
    color: #F6F7F9;
  }
  .fare-discount-button{
    justify-content: flex-end;
    width: auto;
    color: #fff;
      background-color: #2c278b;
      border-color: #292481;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
  }
  .toggle-container {
    width: 100px;
    background-color: #c4c4c4;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    padding: 2px;
    height: 32px;
    position: relative;
  }
  
  .dialog-button {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #362fa9;
    color: white;
    padding: 8px 12px;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-width: unset;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 40px;
    transition: all 0.3s ease;
  }
  
  .disabled {
    background-color: #362fa9;
    left: 0px;
  }
  .toggle-container {
    width: 100px;
    background-color: #c4c4c4;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    padding: 2px;
    height: 32px;
    position: relative;
  }
  
  .dialog-button {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #362fa9;
    color: white;
    padding: 8px 12px;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-width: unset;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 40px;
    transition: all 0.3s ease;
  }
  
  .disabled {
    background-color: #362fa9;
    left: 0px;
  }
  