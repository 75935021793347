.styles_time_table_wrapper__1-rtp {
  overflow: auto;
}

.timetable-evt-container {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  justify-content: start;
  padding-top: 2px;
}

.timetable-evt-container:hover {
  z-index: 2;
  transform: scale(2);
  height: auto !important;
  padding: 5px;
  position: absolute;
}

.trip-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  margin-bottom: 0.5px;
}

.trip-card:hover {
  transform: scaleX(1.05);
}

#case-status-dropdown {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 5px 5px 0;
  border: solid #ced4da 1px;
}

#case-status-picker {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 5px 5px 0;
  border: solid #ced4da 1px;
  padding-left: 8px;
}

.dropdown-menu {
  width: 100%;
}

.status-circle {
  width: 10px;
  height: 10px;
  /* background-color: #6a5bc7; */
  border-radius: 50%;
}

.status-circle-lg {
  width: 20px;
  height: 20px;
  /* background-color: #6a5bc7; */
  border-radius: 50%;
}

.simple-placeholder {
  width: 100%;
  height: 2rem;
  margin-top: 8px;
  border-radius: 5px;
  background: linear-gradient(231deg, #bbc7f2, #6a5bc7);
  background-size: 400% 400%;

  -webkit-animation: GradientFade 3s ease infinite;
  -moz-animation: GradientFade 3s ease infinite;
  -o-animation: GradientFade 3s ease infinite;
  animation: GradientFade 3s ease infinite;
}

@media screen and (max-width: 600px) {
  .timetable-evt-container:hover {
    width: fit-content;
    /* position:absolute;
    margin-left: -10vw;
    z-index: 9999; */
  }
}

@-webkit-keyframes GradientFade {
  0% {
    background-position: 79% 0%;
  }
  50% {
    background-position: 22% 100%;
  }
  100% {
    background-position: 79% 0%;
  }
}
@-moz-keyframes GradientFade {
  0% {
    background-position: 79% 0%;
  }
  50% {
    background-position: 22% 100%;
  }
  100% {
    background-position: 79% 0%;
  }
}
@-o-keyframes GradientFade {
  0% {
    background-position: 79% 0%;
  }
  50% {
    background-position: 22% 100%;
  }
  100% {
    background-position: 79% 0%;
  }
}
@keyframes GradientFade {
  0% {
    background-position: 79% 0%;
  }
  50% {
    background-position: 22% 100%;
  }
  100% {
    background-position: 79% 0%;
  }
}
