.signup-main {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  position: relative;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.signup-bg{
    position: fixed;
    align-self: center;
    animation: morph 8s ease-in-out infinite;
}

.products-img-signup{
  width: 75%;
}

.product-text-signup{
  width: 100%;
  font-weight: 700;
  -webkit-text-stroke: .5px white;
}

@media screen and (max-width: 992px) {
  .logo-primary{
    text-align: center;
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 20% 30% 30%;
    height: 90%;
  }

  50% {
    border-radius: 30% 0% 60% 40%;
    height: 80%;
  }

  100% {
    border-radius: 60% 20% 30% 30%;
    height: 90%;
  }
}