.dropdown-menu-right{
    width: 200px !important;
}
.dropdown-item{
    /* width: 200px !important; */
    /* word-wrap: break-word !important; */
    
     overflow-wrap: break-word !important;
     white-space: pre-line !important
     /* overflow:hidden !important */
}
