.loading-modal {
    z-index: 9998 !important;
  }
  .add-schedule-button{
    justify-content: center;
    width: 90%;
    color: #fff;
      background-color: #2c278b;
      border-color: #292481;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
     
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
  }
  
  .button-hover :hover{
    background-color: #2c278b;
  
  }
  .add-new-time :hover{
    transform: scale(1.5);
  
  }
  .add-new-time-icon :hover{
    transform: scale(1);
  
  }
  .scroll
  {
  overflow-x:scroll;
  white-space: nowrap !important;
  }
  .scroll2{
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
  margin-left: 0px !important;
  
  }
  input[type=checkbox]
  {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    padding: 10px;
  }
  .card-list {
    width: 85vw;
    margin: 0 auto;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px; */
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    padding-bottom: 40px;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    /* background-color: #95dada; */
    border: 3px solid #747474;
    border-radius: 4px;
    padding: 3px;
    cursor: pointer;
    margin: 20px 12px;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
  }
  
  .card-container:hover {
    /* transform: scale(1.05); */
    border: 3px solid #2c278b;
  
  }
  .search {
    -webkit-appearance: none;
    border: 2px solid #2c278b;
    border-radius: 10px;
    color: #2c278b;
    outline: none;
    padding: 10px;
    margin-left: auto;
    width: 300px;
    line-height: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .search-block {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .del-schedule :hover{
    transform: scale(1.3);
  
  }
  .del-schedule-icon :hover{
    transform: scale(1);
  }
  .rc-time-picker-input[disabled] {
    color: black !important;
  }