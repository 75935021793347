.profile-photo-input{
    visibility: hidden;
}

.profile-photo-label{
    cursor: pointer;
    width: 100%;
}

.profile-photo-label:hover{
    opacity: .5;
}

.profile-photo-wrapper{
    margin: 20px 0;
    text-align: center;
}

.profile-photo-resize{
    width: 30%;
    max-width: 150px;
}

.profile-photo-resize:hover{
    border: green 5px dashed;
}

@media only screen and (max-width: 800px) {
    .profile-photo-resize{
        width: 90%;
    }
  }