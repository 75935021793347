.settlement-report-template {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #3e3d44;
  padding: 1rem;
}

.settlement-template-header {
  display: flex;
  align-items: center;
}

.settlement-main-logo-wrapper {
  flex-grow: 1;
}

.settlement-main-logo {
  width: 30%;
}

.settlement-address {
  text-align: right;
  width: 25%;
}

.settlement-report-divider {
  width: 100%;
  height: 3px;
  margin: 1rem 0;
  background-color: #362fa9;
}

.settlement-template-header-2 {
  display: flex;
  padding: 0 5px;
}

.info-detail-span {
  font-weight: 400;
  flex: 1;
  margin-right: 1rem;
}

.info-detail-mini {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.info-detail-wrapper {
  display: flex;
  width: 100%;
}

.info-detail-header {
  font-weight: 600;
}

.info-detail-text {
  flex-grow: 1;
  text-align: right;
}

.itemized-report-container {
  display: flex;
  flex-direction: column;
  border: solid 2px #acacac;
  border-radius: 10px;
  margin-top: 2rem;
}

.itemized-report-body {
  display: flex;
  padding: 2rem;
}

.itemized-report-col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.report-vertical-divider {
  width: 1px;
  background-color: #3e3d44;
  margin: 0 1rem;
}

.itemized-report-header {
  color: #362fa9;
  letter-spacing: 1px;
  font-weight: bold;
}

.itemized-item-container {
  margin-left: 1rem;
}

.item-header {
  /* font-weight: 600; */
}

.item-text {
  flex-grow: 1;
  text-align: right;
}

.total-value-wrapper {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-grow: 1;
  margin-top: 1rem;
}

.total-value-prefix {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.total-value-text {
  font-size: 2rem;
}

.itemized-report-footer {
  background-color: #362fa9;
  border-radius: 0 0 8px 8px;
  color: #F6F7F9;
  padding: 0 2rem;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .settlement-template-header-2 {
    flex-direction: column;
  }

  .settlement-address {
    width: 50%;
  }

  .settlement-main-logo {
    width: 50%;
  }
}
