/* Sidebar expanded */
.sidebar {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 250px !important;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    z-index: 9997; /* Above navbar, below toast */
    transition: width 0.3s ease-in-out;
}

/* Sidebar collapsed */
.sidebar-collapse {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 60px !important;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 9997;
    transition: width 0.3s ease-in-out;
}

/* Sidebar main container scrollable */
.nav-main {
    overflow-y: auto;
    overflow-x: hidden;
}

/* Content pushes left when sidebar is expanded */
.main {
    margin-left: 60px;
    height: 100vh;
    transition: margin-left 0.3s ease-in-out;
}

/* Menu item styles */
.menu-item {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

/* Sidebar title - Prevents text from being cut off */
.title {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
    color: #F6F7F9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    transition: max-width 0.2s ease-in-out;
}

/* When sidebar is collapsed, fully hide text but show it on hover */
.sidebar-collapse .title {
    max-width: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, max-width 0.2s ease-in-out;
}

/* Ensure menu text appears when hovered */
.sidebar-collapse .menu-item:hover .title {
    max-width: 160px;
    opacity: 1;
    white-space: nowrap;
    overflow: visible;
    text-overflow: unset;
    background-color: #3e3d44;
    padding-left: 10px;
    position: absolute;
    left: 60px;
    z-index: 10000;
}

/* Make hover effect smooth */
.menu-link:hover {
    background-color: #3e3d44;
    cursor: pointer;
}

/* Offset div to maintain spacing */
.offset-div {
    height: 50px;
}