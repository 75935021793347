.overlay {
    background: #362FA9;
    /* background: #5c57ef; */
    opacity: 1;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
    height: 50px;
    width: 50px;
    padding: 0 15px;
    /* background: #fff url('../../assets/logo/logo_inverse.png') no-repeat 100% 100%; */
    background-size: contain
}

.line {
    position: relative;
    overflow: visible;
    height: 1px;
    background-color: #ddd;
    border: 1px solid #ddd;
    border-collapse: collapse;
}
.agency-item :hover{
    background-color: #362FA9;
    color: #ffffff;
    cursor: pointer;
}