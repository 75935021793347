.bus-route-tables {
    display: flex;
    flex-direction: row;
    gap: 3vw;
    font-family: Arial, sans-serif;
    margin-top: 5vh;
    width: 100%;
}

.route-table {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.route-table h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
}

.scoreboard-table {
    width: 100%;
    border-collapse: collapse;
}

.scoreboard-table th, .scoreboard-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 13px;
}

.scoreboard-table th {
    background-color: #f2f2f2;
    text-align: center;
}

.scoreboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.status-early {
    color: green;
}

.status-late {
    color: red;
}

.status-ontime {
    color: blue;
}